<template>
  <CContainer class="min-vw-100 p-0 container-fluid">
    <CRow class="text-center row col-sm-12" sm="12">
      <CCol class="
          col-sm-6
          p-0
          min-vh-100
          d-flex
          flex-row
          align-items-center
          form-group
        " style="background-color: #4b36cb" sm="6">
        <div class="col-sm-12" style="color: white">
          <img src="img/brand/stayify_white.svg" style="width: 20%" />
          <br />
          <br />
          <p>
            STAYIFY IS A NEW ONLINE COMMERCE PLATFORM. WE HELP TOURISM OPERATORS
            MODERNIZE THEIR ONLINE PRESENCE SO THEY CAN CREATE PROFITABLE,
            BREATHTAKING TRAVEL EXPERIENCES.
          </p>
        </div>
      </CCol>
      <CCol sm="6" class="
          col-sm-6
          p-0
          bg-white
          min-vh-100
          d-flex
          flex-row
          align-items-center
          form-group
        ">
        <div class="col-sm-12 text-left" style="color: black">
          <CForm @submit.prevent="checkForm" method="POST" class="p-5 form-group">
            <label class="alert alert-danger col-sm-12" v-if="failed">
              {{ this.message }}
            </label>
            <h1>Sign in</h1>
            <p class="text-muted">Sign In to your account</p>
            <CInput v-model="authenticationData.userName" prependHtml="<i class='cui-user'></i>" placeholder="Username"
              invalid-feedback="Please enter the user email." autocomplete="Username">
              <template #prepend-content>
                <CIcon name="cil-user" />
              </template>
            </CInput>
            <CInput v-model="authenticationData.password" prependHtml="<i class='cui-lock-locked'></i>"
              placeholder="Password" invalid-feedback="Please enter the user password." type="password"
              autocomplete="curent-password">

              <template #prepend-content>
                <CIcon name="cil-lock-locked" />
              </template>
            </CInput>

            <!-- <CRow>
              <CCol class="text-right">
                <CButton color="link" class="px-0">Recover password</CButton>
              </CCol>
            </CRow> -->

            <CRow>
              <CCol>
                <CLoadingButton :disabled="loading" type="submit" style="background-color: #4b36cb; color: #ffffff"
                  shape="square" class="px-4 btn-lg btn-block">SIGN IN</CLoadingButton>
              </CCol>
            </CRow>
          </CForm>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import axios from "axios";
import store from "../../store";
import Vue from "vue";

// ToDo : There is js error with loading button. Needs to be fixed or replaced with normal button
export default {
  name: "Login",
  data() {
    return {
      authenticationData: {
        userName: null,
        password: null,
      },
      message: null,
      failed: false,
      loading: false,
    };
  },
  methods: {
    checkForm: function (e) {
      if (this.loading) return;
      this.loading = true;
      if (
        !this.authenticationData.userName.length ||
        !this.authenticationData.password.length
      ) {
        this.failed = true;
        this.message = "Please enter username and password.";
        this.loading = false;
        return;
      }
      this.login();
    },
    login() {
      this.loading = true;
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/Account/Login`, self.authenticationData)
        .then(function (response) {
          self.authenticationData.password = null;
          self.authenticationData.userName = null;

          // Set storage data
          localStorage.setItem("api_token", response.data.accessToken);
          localStorage.setItem("api_refresh_token", response.data.refreshToken);
          let user = JSON.stringify(response.data.user);
          localStorage.setItem("user", user);

          // Set first Partner selected
          let data = JSON.parse(localStorage.getItem("user"));
          localStorage.setItem(
            "partner_id",
            data.partnerPermissions[0]["partnerId"]
          );
          localStorage.setItem(
            "partner_name",
            data.partnerPermissions[0]["partnerName"]
          );
          store.commit("instance", localStorage.getItem("partner_name"));
          axios
            .get(`${self.$apiAdress}/v1/Language/GetByCode/en-US`)
            .then(function (response) {
              Vue.prototype.$dateFormat = response.data.datetimeFormat.replace("dd", "DD").replace("tt", "A");
              Vue.prototype.$dateFormatInput = response.data.datetimeFormat.replace("tt", "a");
            });
          //self.$router.push({ path: "dashboard" });
          // Assuming 'stayify' is a part of the route you want to check against
          if (!self.$router.history.current.path.includes('stayify')) {
            // If the current path does not include 'stayify', redirect to dashboard
            self.$router.push('/dashboard');
          } else {
            // If 'stayify' is part of the current path, go back
            self.$router.back();
          }
          self.loading = false;
        })
        .catch(function (error) {
        console.log("error");
        console.log(error);
          self.message = error.response.data.message;
          self.failed = true;
          self.loading = false;
        });
    },
  },
};
</script>
